

































































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      name: "",
      email: "",
      password: "",
      password2: "",
      error: null as any,
      success: false,
    };
  },
  methods: {
    register(e) {
      e.preventDefault();
      if (this.password !== this.password2) {
        alert("Wiederholtes Passwort stimmt nicht mit Passwort überein");
        return;
      }
      this.$store
        .dispatch("register", {
          name: this.name,
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.success = true;
          setTimeout(() => {
            this.$router.push("/events");
          }, 1500);
        })
        .catch((e) => {
          this.error = "Hoppla, das hat nicht geklappt";
          if (e.response.data) {
            this.error = e.response.data;
            if (e.response.data === "Bad Request") {
              this.error = "Diese E-Mail existiert bereits";
            }
          }
        });
    },
  },
});
